import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '~components/Container';
import RichText from '~components/RichText';
import Link from '~components/Link';
import AnimatedPlayButton from './components/AnimatedPlayButton';
import Carousel from './components/AboutVideosCarousel';

const useStyles = makeStyles(() => ({
  testimonialTitle: {
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '30px',
    color: '#fff',
    marginBottom: '25px'
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  testimonialSubtitle: {
    fontSize: '18px',
    lineHeight: '28px',
    marginTop: '10px'
  },
  testimonialUser: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  testimonialAvatar: {
    display: 'flex !important',
    marginRight: '15px',
    width: '40px',
    height: '40px',
    borderRadius: '50%'
  },
  carouselOutline: {
    outline: 'none'
  }
}));

const AboutVideos = ({
  section_title,
  section_subtitle,
  slider_background_color,
  slides,
  section_footnote
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Container background={slider_background_color}>
      <Box width="100%" pb={isXs ? 0 : 4} pt={isXs ? 3 : 8}>
        <RichText
          html={section_title && section_title.html}
          mode="aboutVideosSectionTitle"
          verticalSpacing={4}
        />
        <RichText
          html={section_subtitle && section_subtitle.html}
          mode="teamPageFounderText"
          verticalSpacing={0}
        />

        <Carousel>
          {slides &&
            slides.map(slide => (
              <Box key={slide && slide.title} className={classes.carouselOutline}>
                <RichText
                  html={slide && slide.title && slide.title.html}
                  mode="aboutPageSlideTitle"
                  verticalSpacing={2}
                />
                <RichText
                  html={slide && slide.content && slide.content.html}
                  mode="teamPageFounderText"
                  verticalSpacing={0}
                />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={isXs ? 2 : 6}
                  style={{ marginTop: '14px' }}
                >
                  {slide.videos &&
                    slide.videos.map(video => (
                      <Grid item xs={12} sm={4} key={video && video.url}>
                        <Box
                          position="relative"
                          component={Link}
                          to={video && video.url}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          className={classes.featuredVideoSpacing}
                        >
                          <img
                            className={`${classes.media} lazyload`}
                            src={video && video.cover_image && video.cover_image.url}
                            alt={video && video.cover_image && video.cover_image.alt}
                          />
                          <AnimatedPlayButton
                            className={classes.circlePosition}
                            externalClassName={classes.circleStyle}
                          />
                        </Box>
                        <Typography variant="subtitle1" className={classes.testimonialSubtitle}>
                          {video && video.title}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}
        </Carousel>
        <RichText
          html={section_footnote && section_footnote.html}
          mode="aboutVideosFootnote"
          verticalSpacing={8}
        />
      </Box>
    </Container>
  );
};

AboutVideos.propTypes = {
  section_title: PropTypes.shape({ html: PropTypes.string }).isRequired,
  section_subtitle: PropTypes.shape({ html: PropTypes.string }).isRequired,
  section_footnote: PropTypes.shape({ html: PropTypes.string }).isRequired,
  slider_background_color: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({ html: PropTypes.string }).isRequired,
      content: PropTypes.shape({ html: PropTypes.string }).isRequired,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          cover_image: PropTypes.shape({
            url: PropTypes.string.isRequired
          }).isRequired,
          title: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default AboutVideos;
