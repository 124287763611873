import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import makeStyles from '@material-ui/core/styles/makeStyles';

import PlayButtonIcon from '~images/animatedPlayButton/play-button.png';

const useStyles = makeStyles(theme => ({
  circle: {
    width: '50px',
    height: '50px',
    background: 'white',
    borderRadius: '50%'
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50px'
    }
  },
  animationPosition: {
    position: 'absolute'
  }
}));

const CircleWrapper = posed.div({
  hidden: {
    transform: 'scale(1.06)',
    transition: {
      duration: 1000
    }
  },
  visible: {
    transform: 'scale(1)',
    transition: {
      duration: 400
    }
  }
});

const Circle = posed.div({
  hidden: {
    transform: 'scale(0.6)',
    opacity: 1,
    transition: {
      duration: 0
    }
  },
  visible: {
    transform: 'scale(1.4)',
    opacity: 0,
    transition: {
      duration: 1000
    }
  }
});

const AnimatedPlayButton = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fadeOut = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 1000);

    return () => clearInterval(fadeOut);
  }, []);

  return (
    <div className={classes.animationPosition}>
      <CircleWrapper className={classes.circle} pose={isVisible ? 'visible' : 'hidden'}>
        <Circle className={classes.circle} pose={isVisible ? 'visible' : 'hidden'} />
        <img src={PlayButtonIcon} alt="play button icon" className={classes.media} />
      </CircleWrapper>
    </div>
  );
};

export default AnimatedPlayButton;
